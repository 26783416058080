import _ from 'lodash'
import axios from 'axios'
import { DEV_ENV, LOCAL_ENV, PROD_ENV, PROD_VPN_ENV, CUSTOM_DEV_ENV } from 'src/constants'

const DEFAULT_STATE = {
  active: 'DEV',
  entries: {
    DEV: DEV_ENV,
    PROD: PROD_ENV,
    PROD_VPN: PROD_VPN_ENV,
    LOCAL: LOCAL_ENV,
    CUSTOM: CUSTOM_DEV_ENV,
  },
}
function getDefaultState() {
  return _.cloneDeep(DEFAULT_STATE)
}

export const environments = {
  state: getDefaultState(),
  reducers: {
    upsertEnvironment(state, payload) {
      console.log({ state, payload })
      return {
        ...state,
        entries: {
          ...state.entries,
          [payload.key]: payload,
        },
      }
    },
    deleteEnvironment(state, payload) {
      const entries = { ...state.entries }
      delete entries[payload]
      return {
        ...state,
        entries,
      }
    },
    setActiveEnvironment(state, payload) {
      return {
        ...state,
        active: payload,
      }
    },
    _setActiveOperatorToken(state, payload) {
      const newState = _.cloneDeep(state)
      _.set(newState, `entries.${state.active}.token`, payload)
      return newState
    },
    _setActiveOperatorUser(state, payload) {
      const newState = _.cloneDeep(state)
      _.set(newState, `entries.${state.active}.operator`, payload)
      return newState
    },
    _setActiveAgentToken(state, payload) {
      console.log({ payload })
      const newState = _.cloneDeep(state)
      _.set(newState, `entries.${state.active}.agent.token`, payload)
      return newState
    },
    _setActiveAgentUser(state, payload) {
      const newState = _.cloneDeep(state)
      _.set(newState, `entries.${state.active}.agent.user`, payload)
      return newState
    },
    logoutOperator(state) {
      const newState = _.cloneDeep(state)
      _.set(newState, `entries.${state.active}.token`, '')
      _.set(newState, `entries.${state.active}.operator`, null)
      return newState
    },
    logoutAgent(state) {
      const newState = _.cloneDeep(state)
      _.set(newState, `entries.${state.active}.agent.token`, '')
      _.set(newState, `entries.${state.active}.agent.user`, null)
      return newState
    },
    reset() {
      return getDefaultState()
    },
    setActiveTenant(state, payload) {
      const newState = _.cloneDeep(state)
      _.set(newState, `entries.${state.active}.tenant`, payload)
      return newState
    },
    clearActiveTenant(state) {
      const newState = _.cloneDeep(state)
      _.set(newState, `entries.${state.active}.tenant`, null)
      return newState
    },
  },
  effects: (dispatch) => ({
    async setActiveOperatorToken(payload, rootState) {
      await dispatch.environments.validateOperatorAuth(payload)
    },
    async setActiveAgentToken(payload, rootState) {
      await dispatch.environments.validateAgentAuth(payload)
    },
    async exchangeAgentToken(payload, rootState) {
      const { api, agent } = rootState.environments.entries[rootState.environments.active]
      const res = await fetch(`${api}/api/v1/auth/token/exchange?tid=${payload}`, {
        headers: {
          Authorization: `Bearer ${agent.token}`,
        },
      })
      if (res.status !== 200) {
        throw new Error('Error Exchanging Token')
      }
      const { token: newToken } = await res.json()
      if (newToken) {
        await dispatch.environments.setActiveAgentToken(newToken)
      } else {
        throw new Error('No Token Returned')
      }
    },
    async validateOperatorAuth(payload, rootState) {
      try {
        const { api, token } = rootState.environments.entries[rootState.environments.active]
        payload = payload || token
        if (payload) {
          const res = await axios.get(`${api}/me`, {
            headers: {
              Authorization: `Bearer ${payload}`,
            },
          })
          dispatch.environments._setActiveOperatorToken(payload)
          dispatch.environments._setActiveOperatorUser(res.data)
        }
      } catch (error) {
        dispatch.environments.logoutOperator()
        throw error
      }
    },
    async validateAgentAuth(payload, rootState) {
      try {
        const { api, agent } = rootState.environments.entries[rootState.environments.active]
        payload = payload || agent?.token
        if (payload) {
          const res = await axios.get(`${api}/me`, {
            headers: {
              Authorization: `Bearer ${payload}`,
            },
          })
          dispatch.environments._setActiveAgentToken(payload)
          dispatch.environments._setActiveAgentUser(res.data)
        }
      } catch (error) {
        console.error(error)
        dispatch.environments.logoutAgent()
        throw error
      }
    },
  }),
}
// setActiveAgentToken
