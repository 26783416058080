import _ from 'lodash'

export const filter = {
  state: '{}',
  reducers: {
    setFilter(_state, filter) {
      return _.cloneDeep(filter)
    },
    mergeFilter(state, filter) {
      return _.merge({}, state, filter)
    },
  },
}
