export const ui = {
  state: {
    sidebarShow: true,
    sidebarUnfoldable: false,
  },
  reducers: {
    toggleSidebar(state) {
      return {
        ...state,
        sidebarShow: !state.sidebarShow,
      }
    },
    toggleFoldable(state) {
      return {
        ...state,
        sidebarUnfoldable: !state.sidebarUnfoldable,
      }
    },
  },
}
