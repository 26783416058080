import _ from 'lodash'
import { z } from 'zod'

export const _AmpEnvironmentConfig = z.object({
  name: z.string(),
  key: z.string(),
  description: z.string(),
  token: z.string(),
  api: z.string(),
  adminApi: z.string(),
  orchestrationApi: z.string(),
  etlApi: z.string(),
  insightsApi: z.string(),
  flowsApi: z.string(),
  slackbotApi: z.string(),
  actionsApi: z.string(),
  eventsApi: z.string(),
  extEventsApi: z.string(),
  temporalUI: z.string(),
  tenant: z.object({ id: z.string(), displayValue: z.string() }).optional(),
  agent: z.object({
    token: z.string(),
    user: z.unknown().nullable(),
  }),
  operator: z.unknown().nullable(),
})
export type AmpEnvironmentConfig = z.infer<typeof _AmpEnvironmentConfig>

export const _AmpEnvironmentsSlice = z.object({
  active: z.string().optional(),
  entries: z.record(_AmpEnvironmentConfig),
})
export type AmpEnvironmentsSlice = z.infer<typeof _AmpEnvironmentsSlice>

export const LOCAL_ENV: AmpEnvironmentConfig = {
  name: 'LOCAL',
  key: 'LOCAL',
  description: 'All services are running on localhost',
  token: '',
  api: 'http://127.0.0.1:8080',
  adminApi: 'http://localhost:8080',
  orchestrationApi: 'http://localhost:8880',
  etlApi: 'http://localhost:8881',
  insightsApi: 'http://localhost:8882',
  flowsApi: 'http://localhost:8893',
  slackbotApi: 'http://localhost:18071',
  actionsApi: 'http://localhost:8894',
  eventsApi: 'http://localhost:8892',
  extEventsApi: 'http://localhost:8892',
  temporalUI: 'http://127.0.0.1:8233/namespaces/default',
  agent: {
    token: '',
    user: null,
  },
  operator: null,
}

export const DEV_ENV: AmpEnvironmentConfig = {
  name: 'DEV',
  key: 'DEV',
  description: 'Deployed Development Environment',
  token: '',
  api: 'https://amp-platform-api-4c7xwv4ska-uc.a.run.app',
  adminApi: 'https://amp-admin-api-4c7xwv4ska-uc.a.run.app',
  orchestrationApi: 'https://amp-platform-orchestration-engine-4c7xwv4ska-uc.a.run.app',
  etlApi: 'https://amp-platform-etl-engine-4c7xwv4ska-uc.a.run.app',
  insightsApi: 'https://amp-platform-insights-engine-4c7xwv4ska-uc.a.run.app',
  flowsApi: 'https://amp-platform-flow-engine-4c7xwv4ska-uc.a.run.app',
  slackbotApi: 'https://amp-platform-slack-bot-4c7xwv4ska-uc.a.run.app',
  actionsApi: 'https://amp-platform-action-engine-4c7xwv4ska-uc.a.run.app',
  eventsApi: 'https://amp-platform-event-engine-4c7xwv4ska-uc.a.run.app',
  extEventsApi: 'https://amp-platform-event-engine-4c7xwv4ska-uc.a.run.app',
  temporalUI: 'https://cloud.temporal.io/namespaces/qa.moaer',
  agent: {
    token: '',
    user: null,
  },
  operator: null,
}

export const CUSTOM_DEV_ENV: AmpEnvironmentConfig = {
  name: 'Custom Development',
  key: 'CUSTOM',
  description: 'Custom Development Environment',
  token: '',
  api: 'https://api.ampsec.xyz',
  adminApi: 'https://api.ampsec.xyz',
  orchestrationApi: 'https://orchestration.api.ampsec.xyz',
  etlApi: 'https://etl.api.ampsec.xyz',
  insightsApi: 'https://insights.api.ampsec.xyz',
  flowsApi: 'https://flows.api.ampsec.xyz',
  slackbotApi: 'https://slackbot.api.ampsec.xyz',
  actionsApi: 'http://localhost:8894',
  eventsApi: 'http://localhost:8892',
  extEventsApi: 'http://localhost:8892',
  temporalUI: 'https://cloud.temporal.io/namespaces/qa.moaer',
  agent: {
    token: '',
    user: null,
  },
  operator: null,
}

export const PROD_ENV: AmpEnvironmentConfig = {
  name: 'PROD',
  key: 'PROD',
  description: 'Deployed Production Environment',
  token: '',
  api: 'https://amp-platform-api-eynzjbgtfa-uc.a.run.app',
  adminApi: 'https://amp-admin-api-eynzjbgtfa-uc.a.run.app',
  orchestrationApi: 'https://amp-platform-orchestration-engine-eynzjbgtfa-uc.a.run.app',
  etlApi: 'https://amp-platform-etl-engine-eynzjbgtfa-uc.a.run.app',
  insightsApi: 'https://amp-platform-insights-engine-eynzjbgtfa-uc.a.run.app',
  flowsApi: 'https://amp-platform-flow-engine-eynzjbgtfa-uc.a.run.app',
  slackbotApi: 'https://amp-platform-slack-bot-eynzjbgtfa-uc.a.run.app',
  actionsApi: 'https://amp-platform-action-engine-eynzjbgtfa-uc.a.run.app',
  eventsApi: 'https://amp-platform-event-engine-eynzjbgtfa-uc.a.run.app',
  extEventsApi: 'https://amp-platform-event-engine-eynzjbgtfa-uc.a.run.app',
  temporalUI: 'https://cloud.temporal.io/namespaces/stg.moaer',
  agent: {
    token: '',
    user: null,
  },
  operator: null,
}

export const PROD_VPN_ENV: AmpEnvironmentConfig = {
  name: 'PROD-VPN',
  key: 'PROD_VPN',
  description: 'Secure Deployed Production Environment',
  token: '',
  api: 'https://api.amplifiersecurity.io',
  adminApi: 'http://admin-api.prod.amplifier.internal',
  orchestrationApi: 'http://orchestration-engine.prod.amplifier.internal',
  etlApi: 'http://etl-engine.prod.amplifier.internal',
  insightsApi: 'http://insights-engine.prod.amplifier.internal',
  flowsApi: 'http://flow-engine.prod.amplifier.internal',
  slackbotApi: 'http://slack-bot.prod.amplifier.internal',
  actionsApi: 'http://action-engine.prod.amplifier.internal',
  eventsApi: 'http://events-engine.prod.amplifier.internal',
  extEventsApi: 'https://events.amplifiersecurity.io',
  temporalUI: 'https://cloud.temporal.io/namespaces/stg.moaer',
  agent: {
    token: '',
    user: null,
  },
  operator: null,
}

export const getDefaultEnvState = (): AmpEnvironmentConfig => ({
  ..._.cloneDeep(DEV_ENV),
  name: 'NEW ENVIRONMENT',
})
