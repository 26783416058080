import persistPlugin from '@rematch/persist'
import { init } from '@rematch/core'
import * as models from './models'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['settings', 'environments', 'filter'],
  version: '2.0.0',
}
export const store = init({
  models,
  plugins: [persistPlugin(persistConfig, undefined, undefined, bootstrapApp)],
})

export default store

function bootstrapApp() {
  validateAuth()
}

function validateAuth() {
  store.dispatch.environments.validateAgentAuth()
  store.dispatch.environments.validateOperatorAuth()
}
